import React from 'react';
import PageTemplate from '../PageTemplate/PageTemplate';
import { useTranslation } from 'react-i18next';

const ThankYou = () => {
    const { t } = useTranslation();

    return (
        <PageTemplate>
            <h1>{t('thank_you')}</h1>
            <div className="page-underline"></div>
            <div className='page-subtitle'>{t('please_wait')}</div>
        </PageTemplate>
    );
};

export default ThankYou;
