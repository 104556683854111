// ProductPageDescription.js
import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PageTemplate from '../PageTemplate/PageTemplate';
import ProductSlider from '../../components/ProductSlider/ProductSlider';
import { useCart } from '../../context/CartContext'; // Импортируйте CartContext
import './ProductPageDescription.css';
import { useTranslation } from 'react-i18next';

function ProductPageDescription({ product }) {
    const { t, i18n } = useTranslation();
    const { cartItems, setCartItems } = useCart();
    const [selectedOption, setSelectedOption] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [isBallAnimating, setIsBallAnimating] = useState(false);
    const [showPopover, setShowPopover] = useState(false);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        setQuantity(1);
        setIsOptionSelected(true);
    };

    const handleQuantityChange = (delta) => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity + delta));
    };

    const totalPrice = selectedOption ? selectedOption.price * quantity : 0;

    useEffect(() => {
        setSelectedOption(null);
        setQuantity(1);
        setIsOptionSelected(false);
        setIsAddedToCart(false);
    }, [product]);

    useEffect(() => {
        if (isAddedToCart) {
            const timer = setTimeout(() => setIsAddedToCart(false), 1000);
            return () => clearTimeout(timer); // очищаем таймер при размонтировании
        }
    }, [isAddedToCart]);

    const handleAddToCart = () => {
        if (!selectedOption) {
            setShowPopover(true); // Показать поповер, если не выбран вариант
            setTimeout(() => setShowPopover(false), 3000); // Скрыть поповер через 3 секунды
            return;
        }

        // Получение пути к первой картинке
        const firstImage = product.slider[0]; // Путь к первой картинке

        const cartItem = {
            productId: product.id,
            title: product.title,
            variant: selectedOption.variant,
            quantity: quantity,
            totalPrice: totalPrice,
            originalPrice: selectedOption.price,
            selectedOption: selectedOption, // Сохраняем весь объект варианта
            image: firstImage // Сохраняем путь к первой картинке
        };

        // Проверяем, есть ли уже товар в корзине
        const existingItemIndex = cartItems.findIndex(item => item.productId === product.id && item.variant === selectedOption.variant);

        if (existingItemIndex >= 0) {
            // Если товар уже есть в корзине, увеличиваем количество
            const updatedCart = cartItems.map((item, index) => {
                if (index === existingItemIndex) {
                    return {
                        ...item,
                        quantity: item.quantity + quantity, // Увеличиваем количество
                        totalPrice: (item.quantity + quantity) * item.originalPrice // Обновляем общую цену
                    };
                }
                return item;
            });
            setCartItems(updatedCart);
        } else {
            // Если товара нет в корзине, добавляем новый товар
            const updatedCart = [...cartItems, cartItem];
            setCartItems(updatedCart);
        }

        // Сохраняем обновленную корзину в localStorage
        localStorage.setItem('cart', JSON.stringify([...cartItems, cartItem]));

        // Меняем текст кнопки на "Added to Cart"
        setIsAddedToCart(true);
        setIsBallAnimating(true);

        // Таймер для отключения анимации шарика
        setTimeout(() => setIsBallAnimating(false), 600); // Убираем шарик через 1 сек
    };


    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>{t('please_select')}</Popover.Body>
        </Popover>
    );

    return (
        <div className="product-page-description">
            <PageTemplate>
                <div className="product-slider-wrapper">
                    <ProductSlider images={product.slider} />
                    <div>
                        <h1>{product.title}</h1>
                        <div className="page-underline"></div>
                        <div className="product-price">
                            {isOptionSelected ? (
                                <h3>€{totalPrice}</h3>
                            ) : (
                                <h3>€{product.price.from} - €{product.price.to}</h3>
                            )}
                            {selectedOption && selectedOption.markdown && (
                                <h3>
                                    <s>€{selectedOption.markdown}</s>
                                </h3>
                            )}
                        </div>
                        <p>
                            {product.additionalDescription && (
                                <>
                                    {product.additionalDescription[i18n.language]}
                                </>
                            )}
                            <br />
                            {product.additionalDescription2 && (
                                <>
                                    {product.additionalDescription2[i18n.language]}
                                </>
                            )}
                        </p>
                        <ul>
                            {product.options.map(option => (
                                <li key={option.variant}>
                                    <button
                                        className={selectedOption && selectedOption.variant === option.variant ? 'active' : ''}
                                        onClick={() => handleOptionChange(option)}
                                    >
                                        {option.variant} {t('pills')}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <div className="quantity-selector">
                            <div className="quantity-selector-nmbr">{quantity}</div>
                            <div className="quantity-selector-buttons">
                                <button onClick={() => handleQuantityChange(1)}>▴</button>
                                <button onClick={() => handleQuantityChange(-1)}>▾</button>
                            </div>
                            <p>{t('choose')}</p>
                        </div>
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            show={showPopover}
                            overlay={popover}
                        >
                            <button
                                className={`btn btn-add-chart-style ${isAddedToCart ? 'btn-added' : 'btn-add-cart'}`}
                                onClick={handleAddToCart}
                            >
                                {isAddedToCart ? t('added_to_cart') : t('add_to_cart')}
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/icons/shopping-cart-white.svg`}
                                    alt=""
                                />
                            </button>
                        </OverlayTrigger>
                    </div>
                </div>
                {isBallAnimating && <div className="cart-ball-animation">1</div>}
                <div className="description-container">
                    <div className="description-container-title">{t('description')}</div>
                    <div className="description-block">
                        <h1>{t('product_description')}</h1>
                        {product.description.active_ingredient[i18n.language] && (
                            <>
                                <span>{t('active_ingredient')}: {product.description.active_ingredient}</span>
                            </>
                        )}
                        <br />
                        {product.description.country_of_manufacture[i18n.language] && (
                            <>
                                <span>{t('country_of_origin')}: {product.description.country_of_manufacture[i18n.language]}</span>
                            </>
                        )}
                        <p></p>
                        {product.description.product_description[i18n.language] && (
                            <>
                                <h4>{t('product_description')}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: product.description.product_description[i18n.language]
                                            .replace(/<p>/g, '<p>')
                                            .replace(/<\/p>/g, '</p>')
                                            .replace(/<strong>/g, '<strong>')
                                            .replace(/<\/strong>/g, '</strong>')
                                            .replace(/<br\/>/g, '<br/>'),
                                    }}
                                />
                            </>
                        )}

                        {product.description.general_use[i18n.language] && (
                            <>
                                <h4>{t('general_use')}</h4>
                                <p>{product.description.general_use[i18n.language]}</p>
                            </>
                        )}
                        {product.description.dosage_and_application_area[i18n.language] && (
                            <>
                                <h4>{t('dosage_and_application')}</h4>
                                <p>{product.description.dosage_and_application_area[i18n.language]}</p>
                            </>
                        )}
                        {product.description.precautions[i18n.language] && (
                            <>
                                <h4>{t('precautions')}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: product.description.precautions[i18n.language]
                                            .replace(/<p>/g, '<p>')
                                            .replace(/<\/p>/g, '</p>')
                                            .replace(/<strong>/g, '<strong>')
                                            .replace(/<\/strong>/g, '</strong>')
                                            .replace(/<br\/>/g, '<br/>'),
                                    }}
                                />
                            </>
                        )}
                        {product.description.contraindications[i18n.language] && (
                            <>
                                <h4>{t('contraindications')}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: product.description.contraindications[i18n.language]
                                            .replace(/<p>/g, '<p>')
                                            .replace(/<\/p>/g, '</p>')
                                            .replace(/<strong>/g, '<strong>')
                                            .replace(/<\/strong>/g, '</strong>')
                                            .replace(/<br\/>/g, '<br/>'),
                                    }}
                                />
                            </>
                        )}
                        {product.description.possible_side_effects[i18n.language] && (
                            <>
                                <h4>{t('possible_side_effects')}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: product.description.possible_side_effects[i18n.language]
                                            .replace(/<p>/g, '<p>')
                                            .replace(/<\/p>/g, '</p>')
                                            .replace(/<strong>/g, '<strong>')
                                            .replace(/<\/strong>/g, '</strong>')
                                            .replace(/<br\/>/g, '<br/>'),
                                    }}
                                />
                            </>
                        )}
                        {product.description.interactions[i18n.language] && (
                            <>
                                <h4>{t('interactions')}</h4>
                                <p>{product.description.interactions[i18n.language]}</p>
                            </>
                        )}
                        {product.description.missed_dose[i18n.language] && (
                            <>
                                <h4>{t('missed_dose')}</h4>
                                <p>{product.description.missed_dose[i18n.language]}</p>
                            </>
                        )}
                        {product.description.overdose[i18n.language] && (
                            <>
                                <h4>{t('overdose')}</h4>
                                <p>{product.description.overdose[i18n.language]}</p>
                            </>
                        )}
                        {product.description.storage[i18n.language] && (
                            <>
                                <h4>{t('storage')}</h4>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: product.description.storage[i18n.language]
                                            .replace(/<p>/g, '<p>')
                                            .replace(/<\/p>/g, '</p>')
                                            .replace(/<strong>/g, '<strong>')
                                            .replace(/<\/strong>/g, '</strong>')
                                            .replace(/<br\/>/g, '<br/>'),
                                    }}
                                />
                            </>
                        )}
                        {product.description.responsibility[i18n.language] && (
                            <>
                                <h4>{t('responsibility')}</h4>
                                <p>{product.description.responsibility[i18n.language]}</p>
                            </>
                        )}
                        {product.description.packaging[i18n.language] && (
                            <>
                                <h4>{t('packaging')}</h4>
                                <p>{product.description.packaging[i18n.language]}</p>
                            </>
                        )}
                    </div>
                </div>
            </PageTemplate>
        </div>
    );
}

export default ProductPageDescription;
