import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import './Header.css';
import { useCart } from '../../context/CartContext';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

function Header() {
    const { t, i18n } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [cartCount, setCartCount] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { cartItems } = useCart();

    const isWhiteHeader = location.pathname === '/';

    const handleSearch = (e) => {
        e.preventDefault();
        navigate(`/search?query=${searchTerm}`);
        setIsMenuOpen(false); // Закрываем меню после поиска
    };

    const handleLanguageChange = () => {
        const newLang = i18n.language === 'de' ? 'en' : 'de';
        i18n.changeLanguage(newLang);
        Cookies.set('i18next', newLang); // Сохраняем язык в куках
    };

    useEffect(() => {
        const updateCartCount = () => {
            const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
            const itemCount = cartItems.reduce((total, item) => total + item.quantity, 0);
            setCartCount(itemCount);
        };

        updateCartCount();

        const handleStorageChange = (e) => {
            if (e.key === 'cart') {
                updateCartCount();
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Блокировка скроллинга при открытии меню
    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isMenuOpen]);

    // Функция для закрытия меню и навигации к нужной странице
    const closeMenuAndNavigate = (path) => {
        setIsMenuOpen(false);
        navigate(path);
    };

    return (
        <>
            <Navbar expand="lg" className={`header-navbar ${isWhiteHeader ? 'header-white' : ''}`}>
                <Container>
                    <Navbar.Brand href="/">
                        <div className="header-logo"></div>
                    </Navbar.Brand>
                    <div className="header-icons">
                        <form onSubmit={handleSearch} className="search-form">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder={`${t('search')}...`}
                                className="search-input"
                            />
                            <button type="submit" className="search-btn">
                                {/* <img src={`${process.env.PUBLIC_URL}/images/icons/search-btn.svg`} alt="Search" /> */}
                            </button>
                        </form>
                        <Link as={Link} to="/chart">
                            <button className="header-btn cart-btn">
                                <img src={`${process.env.PUBLIC_URL}/images/icons/shopping-cart.svg`} alt="Cart" />
                                {cartItems.length > 0 && <div className="cart-count-badge">{cartItems.length}</div>}
                            </button>
                        </Link>
                        <button className="header-btn translate-btn" onClick={handleLanguageChange}>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/icons/${i18n.language === 'de' ? 'us.svg' : 'de.svg'}`}
                                alt="Translate"
                            />
                        </button>
                        <button className="header-btn burger-btn" onClick={toggleMenu}>
                            <img src={`${process.env.PUBLIC_URL}/images/icons/burger-icon.svg`} alt="Menu" />
                        </button>
                    </div>
                </Container>
            </Navbar>

            <div className={`overlay ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className={`burger-wrapper`}>
                    <div className={`burger-menu ${isMenuOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
                        <div className="burger-container">
                            <div className="burger-header">
                                <div className="burger-logo"></div>
                                <button onClick={() => closeMenuAndNavigate('/chart')} className="header-btn cart-btn">
                                    <img src={`${process.env.PUBLIC_URL}/images/icons/shopping-cart.svg`} alt="Cart" />
                                    {cartItems.length > 0 && <div className="cart-count-badge">{cartItems.length}</div>}
                                </button>
                                <button className="header-btn burger-btn burger-close-btn" onClick={toggleMenu}>
                                    <img src={`${process.env.PUBLIC_URL}/images/icons/burger-icon-close.svg`} alt="Close" />
                                </button>
                            </div>

                            <ul className="burger-menu-list">
                                <li><button onClick={() => closeMenuAndNavigate('/catalog')}>{t('catalog')}</button></li>
                                <li><button onClick={() => closeMenuAndNavigate('/payment')}>{t('payment')}</button></li>
                                <li><button onClick={() => closeMenuAndNavigate('/delivery')}>{t('delivery')}</button></li>
                                <li><button onClick={() => closeMenuAndNavigate('/article')}>{t('article')}</button></li>
                                <li><button onClick={() => closeMenuAndNavigate('/faq')}>{t('faq')}</button></li>
                                <li><button onClick={() => closeMenuAndNavigate('/contacts')}>{t('contacts')}</button></li>
                            </ul>
                            <div className="content-buttons">
                                <div className="contact-buttons">
                                    <a href="#" data-aos="fade-up" data-aos-delay="400">
                                        <button className="btn-instagram">
                                        </button>
                                    </a>
                                    <a href="#" data-aos="fade-up" data-aos-delay="600">
                                        <button className="btn-facebook">
                                        </button>
                                    </a>
                                    <a href="#" data-aos="fade-up" data-aos-delay="800">
                                        <button className="btn-twitter">
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className="wrapper-btn">
                                <h1 className='wrapper-btn-title' data-aos="fade-right">{t('continue')}</h1>
                                <Link to="/#catalog" duration={500} className="main-content-btn" data-aos="fade-right">
                                    <div className='btn-text'>{t('watch_catalog')}</div>
                                    <div className='btn-icon'>
                                        <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-down-right-white.svg`} alt="" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
