import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import products from './products.json';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import SearchResults from './pages/SearchResults/SearchResults';
import Payment from './pages/Payment/Payment';
import Delivery from './pages/Delivery/Delivery';
import Article from './pages/Article/Article';
import FAQ from './pages/FAQ/FAQ';
import Contacts from './pages/Contacts/Contacts';
import Chart from './pages/Chart/Chart';
import ProductPageDescription from './pages/ProductPageDescription/ProductPageDescription';
import Order from './pages/Order/Order';
import ThankYou from './pages/ThankYou/ThankYou';

import './App.css';

const ScrollToHashElement = () => {
  const location = useLocation();

  useEffect(() => {
    const scrollToElement = () => {
      if (location.hash) {
        const element = document.getElementById(location.hash.replace('#', ''));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Прокрутка сразу после изменения хэша
    scrollToElement();

    // Повторная попытка через небольшую задержку для уверенности
    setTimeout(scrollToElement, 300);
  }, [location]);

  return null;
};

async function fetchJSON(fileName) {
  const response = await fetch(`${process.env.PUBLIC_URL}/${fileName}`);
  if (!response.ok) {
    throw new Error(`Failed to load ${fileName}`);
  }
  return response.json();
}

const App = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    // Загружаем данные о продуктах и скидках параллельно
    Promise.all([fetchJSON('products.json')])
      .then(([productsData]) => {
        setProducts(productsData);
      })
      .catch(console.error);
  }, []);

  return (
    <Router>
      <ScrollToHashElement />
      <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/article" element={<Article />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/chart" element={<Chart />} />
          <Route path="/order" element={<Order />} />
          <Route path="/thanks" element={<ThankYou />} />
          {products.map(product => (
            <Route
              key={product.title}
              path={`/${product.title}`}
              element={<ProductPageDescription product={product} />}
            />
          ))}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
