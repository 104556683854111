import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import productsData from '../../products.json';
import './Catalog.css';
import { Element } from 'react-scroll';
import PageTemplate from '../PageTemplate/PageTemplate';
import { Link } from 'react-router-dom';
// import { trackEvent } from '../../analytics';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

ReactGA.initialize('G-P2J5NXXLG2');

async function fetchJSON(fileName) {
  const response = await fetch(`${process.env.PUBLIC_URL}/${fileName}`);
  if (!response.ok) {
    throw new Error(`Failed to load ${fileName}`);
  }
  return response.json();
}
// Функция для отправки данных на RedTrack API
// const sendEventToRedTrack = async (eventType, eventData) => {
//   try {
//     const response = await fetch('https://api.redtrack.io/v1/events', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': 'xNqTiEcg3LidHRItaOLF'  // Замените на ваш реальный API-ключ
//       },
//       body: JSON.stringify({
//         event: eventType,
//         data: eventData,
//       })
//     });

//     if (!response.ok) {
//       throw new Error(`Failed to send event: ${response.statusText}`);
//     }

//     const result = await response.json();
//     console.log('Event sent successfully:', result);
//   } catch (error) {
//     console.error('Error sending event to RedTrack:', error);
//   }
// };

function Catalog() {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Загружаем данные о продуктах и скидках параллельно
    Promise.all([fetchJSON('products.json'), fetchJSON('config.json')])
      .then(([productsData, configData]) => {
        setProducts(productsData);
        setDiscounts(configData.discounts);
      })
      .catch(console.error);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const getDiscountInfo = (productTitle) => {
    return discounts.find((item) => item.title === productTitle) || {};
  };

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleProductClick = (product) => {
    if (window.gtag) {
      window.gtag('event', 'click', {
        event_category: 'Catalog',
        event_label: product.title,
      });
    } else {
      console.error('Google Analytics is not initialized.');
    }
  };
  


  return (
    <Element name="section1">
      <PageTemplate>
        <Row xs={1} sm={2} md={3} className="g-6">
          {(isMobile ? products.slice(0, visibleCount) : products).map((product) => {
            const { discountPercentage = 0, promotion, new: isNew, recommended, hit } = getDiscountInfo(product.title);
            const discountedPrice = discountPercentage
              ? (product.price.from * (1 - discountPercentage / 100)).toFixed(2)
              : product.price.from;

            return (
              <Col key={product.id}>
                <Card>
                  <div className="img-container">
                    <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/${product.imageUrl}`} alt={product.title} />
                    <div className="sticker-container">
                      {
                        discountPercentage > 0 ? (
                          <span className="sticker discount-percentage">-{discountPercentage}%</span>
                        ) : (
                          <></>
                        )}
                      {promotion && <span className="sticker promotion">Promo</span>}
                      {isNew && <span className="sticker new">New</span>}
                      {recommended && <span className="sticker recommended">Recommended</span>}
                      {hit && <span className="sticker hit">Hit</span>}
                    </div>
                  </div>
                  <Card.Body>
                    <div>
                      <Card.Title>{product.title}</Card.Title>
                      <Card.Text>€{product.price.from} - €{product.price.to}</Card.Text>
                    </div>
                    <Link
                      to={`/${product.title}`}
                      className='catalog-btns btn'
                      onClick={() => handleProductClick(product)} // Обработчик клика
                    >
                      {t('select_options')}
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>

        {isMobile && visibleCount < products.length && (
          <div className="d-flex justify-content-center mt-3 mx-auto">
            <Button onClick={handleShowMore}>
              Load More
              <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-down.svg`} alt="" />
            </Button>
          </div>
        )}
      </PageTemplate>
    </Element>
  );
}

export default Catalog;
