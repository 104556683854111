import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-EV5BC76R8Z'); // Укажите ваш Tracking ID
};

export const trackEvent = (category, action, label, value = 0, additionalParams = {}) => {
    ReactGA.event({
      category,
      action,
      label,
      value,
      ...additionalParams, // Дополнительные параметры
    });
  };
  