import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import './Breadcrumbs.css';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { t } = useTranslation();

  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
        {t('home')}
      </Breadcrumb.Item>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;

        // Попытка перевести текущий элемент пути
        const displayValue = t(value, {
          defaultValue: decodeURIComponent(value).replace(/_/g, ' ').replace(/-/g, ' ')
        });

        return isLast ? (
          <Breadcrumb.Item active key={to}>
            {displayValue.charAt(0).toUpperCase() + displayValue.slice(1)}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item linkAs={Link} linkProps={{ to }} key={to}>
            {displayValue.charAt(0).toUpperCase() + displayValue.slice(1)}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
