import React, { useState, useCallback } from "react";
import IntlTelInput from "intl-tel-input/react";
import debounce from "lodash.debounce";
import "intl-tel-input/build/css/intlTelInput.css";

const PhoneInput = ({ userInfo, setUserInfo, inputError }) => {
    const [isValid, setIsValid] = useState(true); // Состояние валидности
    //   const [countryCode, setCountryCode] = useState(""); // Код страны
    const [phoneNumber, setPhoneNumber] = useState(""); // Введённый номер телефона

    // Дебаунс-функция для обработки изменений номера
    const handlePhoneInputDebounced = useCallback(
        debounce((value, countryData) => {
            const sanitizedValue = value.replace(/[^0-9]/g, ""); // Удаляем нецифровые символы

            if (sanitizedValue.length <= 15) { // Максимум 15 символов
                setPhoneNumber(sanitizedValue); // Сохраняем номер телефона

                // Обновляем состояние userInfo
                setUserInfo((prev) => ({
                    ...prev,
                    phone: sanitizedValue,
                }));

                // // Сохраняем код страны
                // if (countryData?.dialCode) {
                //   setCountryCode(countryData.dialCode);
                // }
            }
        }, 300), // Задержка 300 мс
        [setUserInfo]
    );

    return (
        <div>
            <IntlTelInput
                initialValue={userInfo.phone || ""}
                initOptions={{
                    initialCountry: "de",
                    geoIpLookup: (callback) => {
                        fetch("https://ipapi.co/json/")
                            .then((res) => res.json())
                            .then((data) => callback(data.country_code))
                            .catch(() => callback("de"));
                    },
                    separateDialCode: true,
                    utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input/build/js/utils.js",
                }}
                onChangeNumber={(value, countryData) => {
                    handlePhoneInputDebounced(value, countryData); // Вызываем обработчик с дебаунсом
                }}
                onChangeValidity={setIsValid}
                inputProps={{
                    maxLength: 15,
                    className: inputError.phone || !isValid ? "iti__tel-input input-error" : "iti__tel-input",
                }}
            />
        </div>
    );
};

export default PhoneInput;
