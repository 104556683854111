import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Footer.css';
import { useTranslation } from 'react-i18next';
// import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate(); // Создаем навигатор
    const location = useLocation();
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);  // Прокрутка страницы к началу
    }, [location]); // Срабатывает при каждом изменении пути

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchTerm) {
            navigate(`/search?query=${searchTerm}`); // Используем navigate для перенаправления
        } else {
            setIsSearchVisible(!isSearchVisible);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // плавная прокрутка
        });
    };

    return (
        <Navbar expand="lg" className="footer-navbar">
            <Container>
                <div className="nav-brand-wrapper">
                    <Navbar.Brand href="/">
                        <img src={`${process.env.PUBLIC_URL}/main-logo.svg`} alt="Vitapillen" />
                    </Navbar.Brand>
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link as={Link} to="/payment">{t('payment')}</Nav.Link>
                        <Nav.Link as={Link} to="/delivery">{t('delivery')}</Nav.Link>
                        <Nav.Link as={Link} to="/article">{t('article')}</Nav.Link>
                        <Nav.Link as={Link} to="/faq">{t('faq')}</Nav.Link>
                        <Nav.Link as={Link} to="/contacts">{t('contacts')}</Nav.Link>
                    </Nav>
                    <Nav className="align-items-center">
                        <form onSubmit={handleSearch} className="search-form">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder={`${t('search')}...`}
                                className={`search-input ${isSearchVisible ? 'visible' : ''}`}
                            />
                            <button type="submit" className="search-btn"></button>
                        </form>
                        <Link as={Link} to="/chart">
                            <button className="footer-btn cart-btn">
                                <img src={`${process.env.PUBLIC_URL}/images/icons/shopping-cart.svg`} alt="" className="icon-shopping-cart" />
                            </button>
                        </Link>

                        <button onClick={scrollToTop} className="scroll-to-top-btn">
                            <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-up.svg`} alt="Scroll to top" className="icon-scroll-up" />
                        </button>
                    </Nav>

                </Navbar.Collapse>
            </Container>
            <div className="footer-menu-underline" />
            <Container className='footer-submenu'>
                <div className="submenu-wrapper" dangerouslySetInnerHTML={{ __html: t('all_rights') }} />
            </Container>
            <Container className='submenu-container'>
                <div className="submenu-buttons">
                    <a href="#">{t('privacy_policy')}</a>
                    <a href="#">{t('terms_of')}</a>
                </div>
            </Container>
        </Navbar>
    )
}

export default Header;