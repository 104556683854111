import React from 'react';
import PageTemplate from '../PageTemplate/PageTemplate';
import { useTranslation } from 'react-i18next';

const Contacts = () => {
  const { t } = useTranslation();
  return (
    <PageTemplate>
      <h1>{t('contacts')}</h1>
      <div className="page-underline"></div>
      <div className='page-subtitle'>
        {t('please_contact_us')}
        <br />
        <a href="mailto:info@vitapillen.org">
          <img src={`${process.env.PUBLIC_URL}/images/icons/mail-btn.svg`} alt="" />
          info@vitapillen.org
        </a>
        <div className="contact-buttons">
          <a href="#" data-aos="fade-up" data-aos-delay="400">
            <button className="btn-instagram"></button>
          </a>
          <a href="#" data-aos="fade-up" data-aos-delay="600">
            <button className="btn-facebook"></button>
          </a>
          <a href="#" data-aos="fade-up" data-aos-delay="800">
            <button className="btn-twitter"></button>
          </a>
        </div>
      </div>
    </PageTemplate>
  );
};

export default Contacts;
