import React from 'react';
import PageTemplate from '../PageTemplate/PageTemplate';
import { useTranslation } from 'react-i18next';

const Article = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <h1>{t('article')}</h1>
      <div className="page-underline"></div>
      <div className='page-subtitle'>
        <p>{t('here_is_article')}</p>
      </div>
    </PageTemplate>
  );
};

export default Article;
