import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
// import './MailInput.css'; // Создайте CSS-файл для стилей

const MailInput = ({ value, onChange, placeholder, className, maxLength, required }) => {
  const [isFocused, setIsFocused] = useState(false); // Для отслеживания фокуса
  const { t } = useTranslation();

  // Подсказки для доменов
  const domainSuggestions = [
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'icloud.com',
    'mail.ru',
    'yandex.ru',
    'aol.com',
    'hotmail.com',
    'zoho.com',
    'protonmail.com',
    'tutanota.com',
    'fastmail.com',
    'gmx.com',
    'inbox.com',
    'me.com',
    'hushmail.com',
    'libero.it',
    'orange.fr',
    'hotmail.co.uk',
    'live.com',
    'msn.com',
    'runbox.com',
    'comcast.net',
    'verizon.net',
    'att.net',
    'sbcglobal.net',
    'cox.net',
    'earthlink.net',
    'blueyonder.co.uk',
    'mail.com',
    'outlook.co.uk',
    'mailchimp.com',
    'yahoo.co.uk',
    'icloud.co.uk',
    'gmx.net',
    'yandex.com',
    'btinternet.com',
    'sky.com',
    'virginmedia.com',
    'kpnmail.nl',
    'seznam.cz',
    'zoho.in',
    'tutanota.de',
    'fastmail.fm'
  ];

  // Получаем текст подсказки (например, "@gmail.com")
  const getDomainSuggestion = (email) => {
    if (!email) return '';
    const [localPart, domainPart] = email.split('@');
    if (!domainPart) return '';

    const suggestion = domainSuggestions.find((d) => d.startsWith(domainPart));
    return suggestion ? suggestion.slice(domainPart.length) : '';
  };

  const domainSuggestion = getDomainSuggestion(value);

  const handleInputChange = (e) => {
    const sanitizedValue = e?.target?.value?.replace(/[^a-zA-Z0-9@._\-]/g, '') || '';
    onChange(sanitizedValue);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (domainSuggestion) {
      onChange(value + domainSuggestion); // Добавляем подсказку к значению при потере фокуса
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && domainSuggestion) {
      onChange(value + domainSuggestion);
    }
  };

  return (
    <div className="mail-input-wrapper">
      <OverlayTrigger
        placement="bottom"
        overlay={
          domainSuggestion && isFocused ? (
            <Tooltip id="domain-tooltip">
              <div>{t('click')}</div>
              {value.split('@')[0] + '@' + getDomainSuggestion(value)}
            </Tooltip>
          ) : <></>
        }
      >
        <div className="input-container" style={{ position: 'relative' }}>
          <input
            id="email"
            type="email"
            placeholder={placeholder}
            value={value || ''} // Предотвращаем undefined
            onChange={handleInputChange}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            maxLength={maxLength}
            required={required}
            className={`mail-input ${className}`}
          />
        </div>
      </OverlayTrigger>
    </div>
  );
};

MailInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
};

MailInput.defaultProps = {
  placeholder: 'Enter your email',
  className: '',
  maxLength: 50,
  required: false,
};

export default MailInput;