import React from 'react';
import PageTemplate from '../PageTemplate/PageTemplate';
import { useTranslation } from 'react-i18next';

const Payment = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <h1>{t('payment')}</h1>
      <div className="page-underline"></div>
      <div className='page-subtitle'>
        <p>{t('you_can')}</p>

        <p>{t('for_faster')}</p>

        <p>{t('once_the')}</p>

        <p>{t('to_order')}</p>

        <p>{t('we_accept')}</p>

        <p>– {t('sepa_credit')} <br />
          – {t('transferring_funds')}</p>

        {t('please_specify')}
      </div>
    </PageTemplate>
  );
};

export default Payment;
