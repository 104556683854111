import React from 'react';
import PageTemplate from '../PageTemplate/PageTemplate';
import { useTranslation } from 'react-i18next';


const Delivery = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <h1>{t('delivery')}</h1>
      <div className="page-underline"></div>
      <div className='page-subtitle'>
        <p>{t('our_product')}</p>

        <p>{t('the_drugs')}</p>

        <p>{t('the_orders')}</p>
      </div>
    </PageTemplate>
  );
};

export default Delivery;
