import React, { useState, useEffect } from 'react';
import PageTemplate from '../PageTemplate/PageTemplate';
import Accordion from 'react-bootstrap/Accordion';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Order.css';
import PhoneInput from '../../components/PhoneInput/PhoneInput';
import MailInput from '../../components/MailInput/MailInput';

const Order = () => {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Используем хук useNavigate
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [deliveryInfo, setDeliveryInfo] = useState({
        country: '',
        city: '',
        address: '',
        postalCode: ''
    });
    const [cartItems, setCartItems] = useState([]);
    const [userInfo, setUserInfo] = useState({
        name: '',
        surname: '',
        phone: '',
        email: ''
    });
    const deliveryCost = 0;

    const [accordionOpen, setAccordionOpen] = useState({
        delivery: false,
        cart: false
    });

    const [deliverySaved, setDeliverySaved] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const [showConfirmPopover, setShowConfirmPopover] = useState(false);
    const [inputError, setInputError] = useState({
        name: false,
        surname: false,
        phone: false,
        email: false,
        city: false,
        address: false,
        postalCode: false
    });

    useEffect(() => {
        const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
        const savedDeliveryInfo = JSON.parse(localStorage.getItem('deliveryInfo')) || deliveryInfo;
        const savedUserInfo = JSON.parse(localStorage.getItem('userInfo')) || userInfo;
        setCartItems(savedCart);
        setDeliveryInfo(savedDeliveryInfo);
        setUserInfo(savedUserInfo);

        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);


    const handleDeliveryChange = (field, value) => {
        // Валидация для каждого поля
        const sanitizedValue = sanitizeField(field, value);

        setDeliveryInfo((prevInfo) => ({ ...prevInfo, [field]: sanitizedValue }));
        setInputError((prevError) => ({ ...prevError, [field]: false })); // Сброс ошибки при вводе
    };

    // Функция очистки значений в зависимости от поля
    const sanitizeField = (field, value) => {
        switch (field) {
            case 'city': // Только буквы, пробелы, тире
                return value.replace(/[^a-zA-Zа-яА-ЯёЁ\s\-]/g, '');
            case 'address': // Буквы, цифры, пробелы, запятые, тире
                return value.replace(/[^a-zA-Zа-яА-ЯёЁ0-9\s,.\-]/g, '');
            case 'postalCode': // Только цифры
                return value.replace(/[^0-9]/g, '');
            default:
                return value; // Без изменений для неизвестных полей
        }
    };

    // Общая функция обработки onChange, которая фильтрует вводимые данные в зависимости от типа поля
    const handleUserInfoChange = (field, value) => {
        let sanitizedValue = value;

        if (field === 'phone') {
            sanitizedValue = value.replace(/[^0-9+]/g, ''); // Только цифры и '+'
            if (sanitizedValue.indexOf('+') > 0) {
                sanitizedValue = sanitizedValue.replace(/\+/g, ''); // Только один '+' в начале
            }
        } else if (field === 'email') {
            sanitizedValue = value.replace(/[^a-zA-Z0-9@._\-]/g, ''); // Только допустимые символы
        }

        setUserInfo((prev) => ({ ...prev, [field]: sanitizedValue }));
    };

    const saveDeliveryInfo = () => {
        if (deliveryInfo.address && deliveryInfo.postalCode && deliveryInfo.city) {
            localStorage.setItem('deliveryInfo', JSON.stringify(deliveryInfo));
            setAccordionOpen((prev) => ({ ...prev, delivery: false })); // Close delivery accordion
            setDeliverySaved(true); // Set delivery saved state
            setShowPopover(false); // Hide popover
            setTimeout(() => setDeliverySaved(false), 2000); // Reset after 2 seconds
        } else {
            setShowPopover(true); // Show popover if fields are empty
        }
    };

    const handleConfirmOrder = async () => {
        const newInputError = {
            name: userInfo.name === '',
            surname: userInfo.surname === '',
            phone: userInfo.phone === '',
            email: userInfo.email === '',
            city: deliveryInfo.city === '',
            address: deliveryInfo.address === '',
            postalCode: deliveryInfo.postalCode === ''
        };

        setInputError(newInputError);
        const hasError = Object.values(newInputError).some((error) => error);
        console.error(hasError);

        setShowConfirmPopover(hasError);

        if (!hasError) {
            const orderData = {
                userInfo,
                deliveryInfo,
                cartItems,
                totalPrice: getTotalPrice() + deliveryCost
            };

            try {
                const response = await fetch('../php/send_order.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(orderData)
                });
                const result = await response.json();

                if (result.status === 'success') {
                    localStorage.removeItem('cart');
                    localStorage.removeItem('deliveryInfo');
                    localStorage.removeItem('userInfo');
                    navigate('/thanks');
                } else {
                    console.error("Error sending order:", result.message);
                }
            } catch (error) {
                console.error("Network error:", error);
            }
        } else {
            setAccordionOpen((prev) => ({ ...prev, delivery: true }));
        }
    };

    const getTotalPrice = () => {
        return cartItems.reduce((total, item) => total + item.totalPrice * item.quantity, 0);
    };

    const getDeliveryHeader = () => {
        const { country, city, address, postalCode } = deliveryInfo;

        const deliveryDetails = [];
        if (country) deliveryDetails.push(country);
        if (address) deliveryDetails.push(address);
        if (postalCode) deliveryDetails.push(postalCode);

        return city ? (
            <div className="d-flex align-items-center locate-wrapper">
                <span className='locate-icon'>
                    <img src={`${process.env.PUBLIC_URL}/images/icons/locate.svg`} alt="" />
                </span>
                <div>
                    <strong>{city}</strong>
                    <div className="small-text">
                        {deliveryDetails.length > 0 ? deliveryDetails.join(', ') : ''}
                    </div>
                </div>
                <div className="ms-auto d-flex align-items-center" role="button" onClick={() => setAccordionOpen((prev) => ({ ...prev, delivery: true }))}>
                    {t('edit')} <img className='ms-2' src={`${process.env.PUBLIC_URL}/images/icons/edit.svg`} alt="" />
                </div>
            </div>
        ) : (
            <div className="d-flex align-items-center locate-wrapper">
                <span className='locate-icon'>
                    <img src={`${process.env.PUBLIC_URL}/images/icons/locate.svg`} alt="" />
                </span>
                <div>
                    <strong>{t('delivery_address')}</strong>
                    <div className="small-text">
                        {deliveryDetails.length > 0 ? deliveryDetails.join(', ') : ''}
                    </div>
                </div>
                <div className="ms-auto link-edit d-flex align-items-center" role="button" onClick={() => setAccordionOpen((prev) => ({ ...prev, delivery: true }))}>
                    {t('edit')} <img className='ms-2' src={`${process.env.PUBLIC_URL}/images/icons/edit.svg`} alt="" />
                </div>
            </div>
        );
    };

    const deliveryPopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">{t('required_fields')}</Popover.Header>
            <Popover.Body>
                {t('please_fill_in_the')}
            </Popover.Body>
        </Popover>
    );

    const confirmPopover = (
        <Popover id="popover-confirm">
            <Popover.Header as="h3">{t('required_fields')}</Popover.Header>
            <Popover.Body>
                {t('please_fill_in_all')}
            </Popover.Body>
        </Popover>
    );

    return (
        <div className="order-page">
            <PageTemplate>
                <div className="order-wrapper">
                    <h1 className="mb-3">{t('placing_an_order')}</h1>
                    <div className="page-underline"></div>

                    {/* Delivery Accordion */}
                    <Accordion activeKey={accordionOpen.delivery ? '0' : null} className='accordion-one'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className={deliverySaved ? 'bg-green' : ''}>
                                {deliverySaved ? (
                                    <span className='check'>
                                        <img src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} alt="Saved" />
                                    </span>
                                ) : (
                                    getDeliveryHeader()
                                )}
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="grid-wrapper">
                                    <div className="form-group">
                                        <input
                                            id="country"
                                            type="text"
                                            placeholder={t('country')}
                                            value={deliveryInfo.country}
                                            onChange={(e) => handleDeliveryChange('country', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            id="city"
                                            type="text"
                                            placeholder={t('city')}
                                            value={deliveryInfo.city}
                                            onChange={(e) => handleDeliveryChange('city', e.target.value)}
                                            maxLength="50"
                                            required
                                            className={inputError.city ? 'input-error' : ''}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            id="address"
                                            type="text"
                                            placeholder={t('address')}
                                            value={deliveryInfo.address}
                                            onChange={(e) => handleDeliveryChange('address', e.target.value)}
                                            maxLength="100"
                                            required
                                            className={inputError.address ? 'input-error' : ''}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            id="postalCode"
                                            type="text"
                                            placeholder={t('postal_code')}
                                            value={deliveryInfo.postalCode}
                                            onChange={(e) => handleDeliveryChange('postalCode', e.target.value)}
                                            maxLength="10"
                                            required
                                            className={inputError.postalCode ? 'input-error' : ''}
                                        />
                                    </div>
                                </div>

                                <OverlayTrigger
                                    trigger="click"
                                    placement={isMobile ? "top" : "left"}
                                    overlay={deliveryPopover}
                                    show={showPopover}
                                    onToggle={() => setShowPopover(!showPopover)}
                                >
                                    <Button className="btn btn-primary" onClick={saveDeliveryInfo}>{t('save_address')}</Button>
                                </OverlayTrigger>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    {/* Cart Items Header */}
                    <div className="d-flex align-items-center locate-wrapper cart-items-wrapper">
                        <span className='locate-icon'>
                            <img src={`${process.env.PUBLIC_URL}/images/icons/shopping-cart-blue.svg`} alt="" />
                        </span>
                        <div>
                            <div>
                                <strong>{t('cart_items')}</strong>
                            </div>
                            {cartItems.length > 0 && ( // Условный рендеринг
                                <div className="small-text">
                                    {t('cart_summary.there')} {cartItems.length === 1 ? t('cart_summary.is') : t('cart_summary.are')} <strong>{cartItems.length}</strong> {t('cart_summary.product')}{cartItems.length === 1 ? "" : t('cart_summary.s')} {t('cart_summary.in_the_cart')}
                                </div>
                            )}
                        </div>
                        <Link as={Link} to="/chart" className="ms-auto link-edit d-flex align-items-center">
                            {t('edit')}<img className='ms-2' src={`${process.env.PUBLIC_URL}/images/icons/edit.svg`} alt="" />
                        </Link>
                    </div>

                    {/* User Info */}
                    <div className="user-info mt-3">
                        <h2>{t('recipient')}</h2>
                        <div className="grid-wrapper">
                            <div className="form-group">
                                {/* <label htmlFor="name">Name <span className="text-danger">*</span></label> */}
                                <input
                                    id="name"
                                    type="text"
                                    placeholder={t('name')}
                                    value={userInfo.name}
                                    onChange={(e) => setUserInfo((prev) => ({ ...prev, name: e.target.value }))}
                                    required
                                    className={inputError.name ? 'input-error' : ''}
                                />
                            </div>
                            <div className="form-group">
                                {/* <label htmlFor="surname">Surname <span className="text-danger">*</span></label> */}
                                <input
                                    id="surname"
                                    type="text"
                                    placeholder={t('surname')}
                                    value={userInfo.surname}
                                    onChange={(e) => setUserInfo((prev) => ({ ...prev, surname: e.target.value }))}
                                    required
                                    className={inputError.surname ? 'input-error' : ''}
                                />
                            </div>
                            <div className="form-group">
                                {/* <label htmlFor="phone">Phone Number <span className="text-danger">*</span></label> */}
                                {/* <input
                                    id="phone"
                                    type="text"
                                    placeholder={t('phone')}
                                    value={userInfo.phone}
                                    onChange={(e) => handleUserInfoChange('phone', e.target.value)}
                                    maxLength="15"
                                    required
                                    className={inputError.phone ? 'input-error' : ''}
                                /> */}
                                <PhoneInput
                                    userInfo={userInfo}
                                    setUserInfo={setUserInfo}
                                    inputError={inputError}
                                    className={inputError.phone ? 'input-error' : ''}
                                />
                            </div>
                            <div className="form-group">
                                {/* <label htmlFor="email">Email <span className="text-danger">*</span></label> */}
                                {/* <input
                                    id="email"
                                    type="email"
                                    placeholder={t('email')}
                                    value={userInfo.email}
                                    onChange={(e) => handleUserInfoChange('email', e.target.value)}
                                    maxLength="50"
                                    required
                                    className={inputError.email ? 'input-error' : ''}
                                /> */}
                                <MailInput
                                    value={userInfo.email || ''} // Предотвращаем передачу undefined/null
                                    onChange={(newValue) => handleUserInfoChange('email', newValue)}
                                    placeholder={t('email')}
                                    maxLength={50}
                                    required
                                    className={inputError.email || !userInfo.email?.includes('@') ? 'input-error' : ''}
                                />

                            </div>
                        </div>
                    </div>

                    {/* Total Price */}
                    <div className="total-price">
                        <h1>{t("total")}</h1>
                        <div className="total-price-wrapper">
                            <div className='summary-wrapper'>
                                <div className="cart-summary">
                                    {cartItems.length} {t('cart_summary.product')}{cartItems.length === 1 ? "" : t('cart_summary.s')} {t('for_the_sum')} <div>{getTotalPrice()}€</div>
                                </div>
                                {/* <p className="cart-summary">
                                    {t('delivery_cost')}: <div>{deliveryCost}€</div>
                                </p> */}
                            </div>
                            <div className="page-underline"></div>
                            <div className="cart-summary summary">
                                {t('to_be_paid')}: <div>{getTotalPrice() + deliveryCost}€</div>
                            </div>
                            <div className="page-underline"></div>
                            <OverlayTrigger
                                trigger="click"
                                placement={isMobile ? "top" : "right"}
                                overlay={confirmPopover}
                                show={showConfirmPopover}
                                onToggle={() => setShowConfirmPopover(!showConfirmPopover)}
                            >
                                <Button className="btn btn-confirm-order" onClick={handleConfirmOrder}>{t('confirm_order')}</Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </PageTemplate>
        </div>
    );
};

export default Order;
