import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductSlider = ({ images }) => {
  const sliderForRef = useRef(null);
  const sliderNavRef = useRef(null);

  useEffect(() => {
    // Инициализация слайдера при изменении images
    const initializeSlick = () => {
      if (sliderForRef.current && sliderNavRef.current) {
        $(sliderForRef.current).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          fade: true,
          asNavFor: $(sliderNavRef.current),
        });

        $(sliderNavRef.current).slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          asNavFor: $(sliderForRef.current),
          dots: false,
          focusOnSelect: true,
          arrows: false,
          variableWidth: false,
          centerMode: false,
        });
      }
    };

    // Инициализация и очистка слайдера
    initializeSlick();

    return () => {
      if (sliderForRef.current && $(sliderForRef.current).hasClass('slick-initialized')) {
        $(sliderForRef.current).slick('unslick');
      }
      if (sliderNavRef.current && $(sliderNavRef.current).hasClass('slick-initialized')) {
        $(sliderNavRef.current).slick('unslick');
      }
    };
  }, [images]); // Следим за изменением images

  return (
    <div className="product-slider">
      <div className="slider-for" ref={sliderForRef} key={images.join('-')}>
        {images.map((image, index) => (
          <div key={index}>
            <img className='main-slide' src={`${process.env.PUBLIC_URL}/${image}`} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>

      <div className="slider-nav" ref={sliderNavRef} key={`nav-${images.join('-')}`}>
        {images.map((image, index) => (
          <div className="thumb-slide" key={index}>
            <img src={`${process.env.PUBLIC_URL}/${image}`} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductSlider;
