import React from 'react';
import PageTemplate from '../PageTemplate/PageTemplate';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <h1>{t('faq')}</h1>
      <div className="page-underline"></div>
      <div className='page-subtitle'>
        <Accordion className="custom-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t('what_does')}</Accordion.Header>
            <Accordion.Body>
              {t('the_term')}
              <p />
              {t('the_generic')}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>{t('why_are')}</Accordion.Header>
            <Accordion.Body>
              {t('manufacturers_of')}
              <p />
              {t('all_of_the')}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>{t('can_the_wording')}</Accordion.Header>
            <Accordion.Body>
              {t('yes_the_wording')}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>{t('can_the_shape')}</Accordion.Header>
            <Accordion.Body>
              {t('yes_the_shape')}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </PageTemplate>
  );
};

export default FAQ;
